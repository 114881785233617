import React from "react"
import styled from "styled-components"
const FormComponentContainer = styled.div`
  background: black;
  font-family: Comfortaa;
  color: white;
  z-index: 20;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0 5vw;
`
const FormTitle = styled.div`
  width: 90vw;
  font-weight: bold;
  font-size: 24px;
  margin: 85px 0 15px 0;
`
const FormSubtitle = styled.div`
  width: 90vw;
  margin: 0px 0 42px 0;
  font-weight: 300;
  font-size: 18px; 
`
const FormHTMLElement = styled.form`
  width: 90vw;
`
const FormInputContainer = styled.div`
  margin: 0px 0 42px 0;
  font-weight: 300;
  font-size: 18px; 
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
`
const FormInput = styled.input`
  background: none;
  border: none;
  color: #777777;
  font-size: 18px;
  font-family: 'Comfortaa';
  margin-top: 5px;
  padding: 10px 0 19px 0;
  border-bottom: 1px solid white;
`
const FormTextarea = styled.textarea`
  border: 1px solid white;
  background: none;
  font-size: 18px;
  font-family: 'Comfortaa';
  margin-top: 15px;
  padding: 15px;
  color: white;
`
const SubmitButton = styled.input`
  color: red;
  background: none;
  padding: 8px;
  border-radius: 40px;
  border: 1px solid red;
  width: 100px;
  font-family: 'Comfortaa';
  font-size: 18px;
`
const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const RedText = styled.span`
  color: #FF0000;
`
export default ()=> {
  return(
    <FormComponentContainer>
      <FormTitle>
        Hola! Cómo te ayudamos?
      </FormTitle>
      <FormSubtitle>
        Llena el formulario o envíanos un email a&nbsp;
        <RedText>hola@ecomm3.com</RedText>
      </FormSubtitle>
      <FormHTMLElement
        action="https://formspree.io/hello@ecomm3.com"
        method="POST">
        <FormInputContainer>
          Nombre
          <FormInput name="name" type="text" placeholder="tu nombre"/>
        </FormInputContainer>
        <FormInputContainer>
          Email *
          <FormInput name="email" type="email" placeholder="tu email" required/>
        </FormInputContainer>
        <FormInputContainer>
          Teléfono
          <FormInput name="phone" type="tel" placeholder="tu teléfono"/>
        </FormInputContainer>
        <FormInputContainer>
          Mensaje *
          <FormTextarea name="message" rows="4" placeholder="escribe tu mensaje aquí" required>
          </FormTextarea>
        </FormInputContainer>
        <SubmitButtonContainer>
          <SubmitButton type="submit" value="Enviar"></SubmitButton>
        </SubmitButtonContainer>
      </FormHTMLElement>
    </FormComponentContainer>
  )
}
